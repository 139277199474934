
import { defineComponent, ref, computed } from '@vue/composition-api';
import { Field } from '../index';

interface ParticipantResume {
  studentLinkedinProfile: string;
}

interface ResumeField extends Field {
  value: ParticipantResume;
  setup: never;
}

export default defineComponent({
  name: 'MDigitalResume',
  emit: ['submit'],
  props: {
    fetchProgram: {
      required: false,
      type: [Object, Function],
      default: () => {}
    },
    adkType: {
      required: false,
      type: String,
      default: ''
    },
    userType: {
      required: true,
      type: String,
      default: ''
    },
    value: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(_props: any, _ctx: any) {
    const data = {
      studentLinkedinProfile: ''
    };

    const programDocument: any = computed({
      get: () => _props.value,
      set: newVal => {
        _ctx.emit('input', newVal);
      }
    });

    const adkIndex = computed(() => {
      const indx = programDocument.value?.data.adks?.findIndex(adk => {
        if (adk) {
          return adk.name === 'Resume';
        }
        return false;
      });
      return indx;
    });

    const fieldIndex = computed(() => {
      const Fdindx = programDocument.value?.data?.adks?.[adkIndex.value]?.fields?.findIndex(
        field => {
          if (field.name) {
            return field.name === 'm-digital-resume';
          }
          return false;
        }
      );
      return Fdindx;
    });

    const fieldValue = computed<ResumeField>({
      get: () => programDocument.value?.data?.adks?.[adkIndex.value]?.fields[fieldIndex.value],
      set: newVal => {
        if (fieldValue.value)
          programDocument.value.data.adks[adkIndex.value].fields[fieldIndex.value] = newVal;
      }
    });

    const participantResume = computed<ParticipantResume>({
      get: () => {
        if (!fieldValue.value.value) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          fieldValue.value.value = {
            studentLinkedinProfile: ''
          };
        }
        if (!fieldValue.value.value.studentLinkedinProfile)
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          fieldValue.value.value.studentLinkedinProfile = '';
        return fieldValue.value.value;
      },
      set: newVal => {
        fieldValue.value.value = newVal;
      }
    });

    const userIsEmployer = ref(_props.userType === 'organizer');

    return {
      userIsEmployer,
      participantResume,
      programDocument
    };
  }
});
